import SuccessIcon from "../../assets/svg/SuccessIcon";
import CopyIcon from "../../assets/svg/CopyIcon";
import { Link } from "react-router-dom";
import Button from "./Button";
import React from "react";

const SuccessMessage = ({
  onClickCopy,
  code,
}: {
  onClickCopy: () => void;
  code: string;
}) => (
  <div className="mt-6 md:mt-[30px] flex flex-col items-center">
    <SuccessIcon />
    <p className="text-[#161C2D] mt-4 md:mt-[30px] font-bold text-xl md:text-2xl lg:text-[30px] leading-tight md:leading-[40px] text-center">
      Благодарим Вас, обращение принято
    </p>
    <p className="text-[#161C2D] text-lg md:text-[24px] leading-[26px] mt-4 md:mt-[24px] font-light tracking-[-0.5px] text-center">
      Ожидайте ответ в течение 15 рабочих дней
    </p>
    <div className="flex flex-col md:flex-row mt-8 md:mt-[86px] items-center">
      <p className="text-lg md:text-[24px] text-[#161C2D] font-light mb-2 md:mb-0 md:mr-[30px] leading-[26px] tracking-[-0.5px] text-center md:text-left">
        Номер Вашего обращения
      </p>
      <div className="flex items-center">
        <div className="w-[120px] text-[#161C2D] mr-2.5 text-2xl md:text-[30px] tracking-[-0.5px] leading-[26px] justify-center rounded-[6px] border-[#92939B] border flex items-center h-[44px]">
          {code}
        </div>
        <span className="cursor-pointer" onClick={onClickCopy}>
          <CopyIcon />
        </span>
      </div>
    </div>
    <p className="text-center font-extralight w-full md:w-[614px] mt-4 md:mt-[24px] text-[#161C2D] text-sm md:text-[16px] leading-[24px] tracking-[-0.5px]">
      Сохраните номер Вашего обращения, чтобы отслеживать результаты
      рассмотрения в разделе "Проверить статус обращения" путем ввода этого
      номера.
    </p>
    <p className="text-sm md:text-[16px] mb-8 md:mb-[50px] text-center md:pl-[20px] leading-[26px] md:text-left md:place-self-start font-extralight tracking-[-0.6px] mt-8 md:mt-[90px]">
      Вам не следует разговаривать ни с кем об изложенном нарушении, а также о
      своем обращении на Горячую линию <br /> АО «Фонд национального
      благосостояния «Самрук-Казына», кроме ситуаций, когда с Вами свяжутся
      уполномоченные работники <br /> АО «Фонд национального благосостояния
      «Самрук-Казына» для уточнения деталей, так как иначе конфиденциальность{" "}
      <br /> информации может быть нарушена. Если Вам станет известно об иных
      нарушениях, пожалуйста, обратитесь на Горячую линию
    </p>
    <Link to="/">
      <Button className="bg-[#A88C6D] text-white w-full md:w-auto">
        На Главную
      </Button>
    </Link>
  </div>
);

export default SuccessMessage;
