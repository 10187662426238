import Header from "../components/Header";
import FirstSection from "../components/home/sections/FirstSection";
import SecondSection from "../components/home/sections/SecondSection";
import ThirdSection from "../components/home/sections/ThirdSection";
import QuestionsSection from "../components/home/sections/QuestionsSection";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <FirstSection />
        <SecondSection />
        <ThirdSection />
        <QuestionsSection />
      </main>
      <Footer />
    </div>
  );
};

export default Home;
