import React, { HtmlHTMLAttributes, useId } from "react";

type Props = {
  label?: string;
  className?: string;
  inputProps?: HtmlHTMLAttributes<HTMLInputElement>;
  value?: string;
  onChangeText: (value: string) => void;
};

const FormInput = ({
  label,
  className = "w-full md:w-[670px]",
  inputProps,
  value,
  onChangeText,
}: Props) => {
  const htmlFor = useId();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeText(e.target.value);
  };

  return (
    <div className="flex flex-col w-full">
      <label
        className="mb-2 md:mb-[12px] max-w-full md:max-w-[670px] text-black text-base md:text-[18px] leading-tight md:leading-[26px] tracking-[-0.1px]"
        htmlFor={htmlFor}
      >
        {label}
      </label>
      <input
        autoFocus
        tabIndex={-1}
        value={value}
        onChange={handleChange}
        {...inputProps}
        className={`${className} h-[40px] md:h-[50px] rounded-[8px] border border-[#E7E9ED] px-3 md:px-[16px] text-black text-sm md:text-[14px] leading-tight md:leading-[26px] tracking-[-0.1px]`}
        type="text"
        id={htmlFor}
      />
    </div>
  );
};

export default FormInput;
