import { SVGProps } from "react";

const LayerSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg width="35" height="37" viewBox="0 0 35 37" fill="none">
    <path
      d="M17.4903 32.1119L3.15 21.0097L0 23.4489L17.5 37L35 23.4489L31.8306 21L17.4903 32.1119Z"
      fill="#473BF0"
      fillOpacity="0.3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 27L31.8208 15.9589L35 13.5L17.5 0L0 13.5L3.16944 15.9396L17.5 27Z"
      fill="#17335D"
    />
  </svg>
);

export default LayerSvg;
