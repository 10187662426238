import React, { useCallback, useState } from "react";
import Container from "../components/Container";
import Header from "../components/Header";
import Breadcrumbs from "../components/Breadcrumbs";
import FormContainer from "../components/FormContainer";
import CopyAlert from "../components/CopyAlert";
import PersonalDataForm from "../components/messageForms/PersonalDataForm";
import SuccessMessage from "../components/home/SuccessMessage";
import useFormsStore from "../store/formValuesStore";
import useDataStore from "../store/store";
import { postMessage } from "../api";

const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB в байтах

const LeaveMessage = () => {
  const [step, setStep] = useState(0);
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [code, setCode] = useState<string>("");

  const { values } = useFormsStore();
  const { data, setAnswers } = useDataStore();

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileBase64Data, setFileBase64Data] = useState<{
    [key: string]: string;
  }>({});

  const fileToBase64 = useCallback((file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }, []);

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const newFiles = Array.from(event.target.files).filter(
          (file) => file.size <= MAX_FILE_SIZE,
        );

        const newFileBase64Data: { [key: string]: string } = {};
        for (const file of newFiles) {
          newFileBase64Data[file.name] = await fileToBase64(file);
        }

        setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setFileBase64Data((prevData) => ({
          ...prevData,
          ...newFileBase64Data,
        }));
      }
    },
    [fileToBase64],
  );

  const handleFileRemove = useCallback((index: number) => {
    setSelectedFiles((prevFiles) => {
      const newFiles = prevFiles.filter((_, i) => i !== index);
      const removedFile = prevFiles[index];

      setFileBase64Data((prevData) => {
        const newData = { ...prevData };
        delete newData[removedFile.name];
        return newData;
      });

      return newFiles;
    });
  }, []);

  const navigate = (code: string) => {
    setStep((prev) => prev + 1);
    setCode(code);
  };

  const submit = () => {
    setAnswers(values);
    postMessage(data, fileBase64Data, navigate);
  };

  const handleNext = () => {
    if (step >= 14) {
      submit();
    } else {
      setStep((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (step > 0) {
      setStep((prev) => prev - 1);
    }
  };

  const onClickCopy = () => {
    navigator.clipboard.writeText(code).then(() => {
      setShowCopyAlert(true);
    });
  };

  const handleHideCopyAlert = () => {
    setShowCopyAlert(false);
  };

  return (
    <main className="bg-[#F5F5F5] min-h-screen">
      <Header className="bg-[#F5F5F5]" />
      <div className="bg-[#F5F5F5] h-full px-4 md:px-8 lg:px-[163px] pb-8 md:pb-[60px]">
        <Breadcrumbs currentPage="Оставить обращение" />
        <Container className="h-fit">
          {step === 0 && <PersonalDataForm onClickNext={handleNext} />}
          {step !== 0 && step < 15 && (
            <FormContainer
              selectedFiles={selectedFiles}
              handleFileChange={handleFileChange}
              handleFileRemove={handleFileRemove}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              step={step}
            />
          )}
          {step >= 15 && (
            <SuccessMessage code={code} onClickCopy={onClickCopy} />
          )}
        </Container>
      </div>
      <CopyAlert show={showCopyAlert} onHide={handleHideCopyAlert} />
    </main>
  );
};

export default LeaveMessage;
