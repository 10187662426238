import React, { PropsWithChildren } from "react";

const Box = ({
  children,
  className,
}: { className?: string } & PropsWithChildren) => {
  return (
    <div
      className={`${className} w-full md:w-[350px] flex-col items-center flex px-4 md:px-[26px] py-6 md:py-10 rounded-[10px] bg-[#232C43] min-h-[300px] md:h-[408px]`}
    >
      {children}
    </div>
  );
};

export default Box;
