import { FC } from "react";
import FormInput from "../FormInput";
import useFormsStore from "../../store/formValuesStore";
import { useTranslation } from "react-i18next";

const FifthForm: FC = () => {
  const { values, setValue } = useFormsStore();
  const handleChange = (value: string) => {
    setValue(5, value);
  };
  const { t } = useTranslation();

  return (
    <div>
      <FormInput
        value={values[5].value}
        onChangeText={handleChange}
        label={t("questions.6")}
      />
    </div>
  );
};

export default FifthForm;
