import { create } from "zustand";

type Question = {
  ID: number;
  QuestionRU: string;
  QuestionKZ: string;
  QuestionEN: string;
  Answer: string;
};

type Questionnaire = {
  TestsID: number;
  questionares: Question[];
};

type UserModel = {
  ID: number;
  Name: string;
  Email: string;
  Phone: string;
};

export type Options = {
  DeclarerTypeID?: number;
  DeclarerRoleID?: number;
  organizationModel: { BranchID?: number };
};

type State = {
  data: {
    questionare: Questionnaire;
    userModel: UserModel;
  } & Options;
};

type Actions = {
  setAnswer: (id: number, answer: string) => void;
  setPersonalData: (data: UserModel) => void;
  setOptions: (options: Options) => void;
  setAnswers: (answers: { id: number; value: string }[]) => void;
};

type Store = State & Actions;

const useDataStore = create<Store>((set, get) => ({
  data: {
    DeclarerTypeID: undefined,
    DeclarerRoleID: undefined,
    organizationModel: { BranchID: undefined },
    userModel: { ID: 0, Name: "", Email: "", Phone: "" },
    questionare: {
      TestsID: 1,
      questionares: [
        {
          ID: 1,
          QuestionRU: "Опишите нарушение, о котором Вы хотите сообщить.",
          QuestionKZ: "Сіз хабарлағыңыз келген бұзушылықты сипаттаңыз.",
          QuestionEN: "Please describe the violation you want to report.",
          Answer: "",
        },
        {
          ID: 2,
          QuestionRU: "Опишите круг лиц, вовлеченных в нарушение.",
          QuestionKZ: "Бұзушылықты қатысы бар адамдар шеңберін сипаттаңыз.",
          QuestionEN: "Please describe the persons involved in the violation.",
          Answer: "",
        },
        {
          ID: 3,
          QuestionRU:
            "Почему Вы считаете, что именно эти люди вовлечены в происходящее?",
          QuestionKZ:
            "Неліктен бұл адамдар болып жатқан оқиғаларға қатысады деп ойлайсыз?",
          QuestionEN:
            "Why do you think that these people are involved in the situation?",
          Answer: "",
        },
        {
          ID: 4,
          QuestionRU: "Как Вы узнали об этом нарушении?",
          QuestionKZ: "Сіз бұл заң бұзушылықты қалай білдіңіз?",
          QuestionEN: "How did you find out about this violation?",
          Answer: "",
        },
        {
          ID: 5,
          QuestionRU: "Когда данное нарушение впервые произошло?",
          QuestionKZ: "Бұл бұзушылық алғаш қашан пайда болды?",
          QuestionEN: "When did this violation first occur?",
          Answer: "",
        },
        {
          ID: 6,
          QuestionRU:
            "Данное нарушение продолжает существовать и в настоящий момент?",
          QuestionKZ:
            "Бұл бұзушылық жалғасуда, қазіргі уақытта бұл әрекеттер жасалуда ма?",
          QuestionEN:
            "Does this violation still continues to exist at the moment?",
          Answer: "",
        },
        {
          ID: 7,
          QuestionRU: "Сколько случаев данного нарушения Вам точно известны?",
          QuestionKZ: "Осы бұзушылықтың қанша жағдайын нақты білесіз?",
          QuestionEN: "How many cases of this violation do you know about?",
          Answer: "",
        },
        {
          ID: 8,
          QuestionRU:
            "Вовлечены ли в это нарушение люди, не являющиеся работниками Компании? Кто они? Работниками какой компании они являются?",
          QuestionKZ:
            "Компанияның қызметкерлері болып табылмайтын адамдар осы бұзушылыққа қатысады ма? Олар кім? Олар қандай компанияның қызметкерлері?",
          QuestionEN:
            "Are people who are not employees of the Company involved in this violation? Who are they? Where do they work?",
          Answer: "",
        },
        {
          ID: 9,
          QuestionRU:
            "Знают ли фигуранты нарушения о Вашей осведомленности о происходящем и об их участии в происходящем?",
          QuestionKZ:
            "Қатысқан адамдар сіздің не болып жатқанын білгеніңізді және олардың болып жатқан оқиғаларға қатысуы туралы біле ме?",
          QuestionEN:
            "Do the persons involved in the violation know about your awareness of what is happening and about their participation in the situation/violation?",
          Answer: "",
        },
        {
          ID: 10,
          QuestionRU: "Кто еще может знать об этом нарушении?",
          QuestionKZ: "Бұл бұзушылық туралы тағы кім біле алады?",
          QuestionEN: "Who else can know about this violation?",
          Answer: "",
        },
        {
          ID: 11,
          QuestionRU:
            "Есть ли у Вас какие-либо документальные подтверждения предоставленной информации? Вы можете прикрепить файлы ниже.",
          QuestionKZ:
            "Сіз берген ақпаратты растайтын қандай да бір құжаттарыңыз бар ма? Төменде файлдарды тіркей аласыз.",
          QuestionEN:
            "Do you have any documentary evidence to support the information provided? You can attach files below.",
          Answer: "",
        },
        {
          ID: 12,
          QuestionRU:
            "Если данные документы находятся не у Вас, могли бы Вы их получить?",
          QuestionKZ:
            "Егер бұл құжаттар сіздің қасыңызда болмаса, оларды ала аласыз ба?",
          QuestionEN:
            "If these documents are not with you, could you get them?",
          Answer: "",
        },
        {
          ID: 13,
          QuestionRU:
            "Информировали ли Вы кого-либо из представителей Компании об этом нарушении?",
          QuestionKZ:
            "Сіз бұл бұзушылық туралы Компанияның кез-келген өкіліне хабарладыңыз ба?",
          QuestionEN:
            "Have you informed any of the Company's representatives about this violation?",
          Answer: "",
        },
        {
          ID: 14,
          QuestionRU: "Если информировали, кого именно? Когда?",
          QuestionKZ: "Егер хабарланасыз, дәл кімге байланыстыңыз? Қашан?",
          QuestionEN: "If you have informed, who exactly? When?",
          Answer: "",
        },
        {
          ID: 15,
          QuestionRU:
            "Были ли предприняты Компанией какие-либо меры в отношении данного нарушения?",
          QuestionKZ:
            "Осы бұзушылықтa байланысты Компания қандай да бір шаралар қабылдады ма?",
          QuestionEN:
            "Has the Company taken any measures in relation to this violation?",
          Answer: "",
        },
        {
          ID: 16,
          QuestionRU:
            "Обладаете ли Вы еще какой-либо информацией, которой хотели бы поделиться?",
          QuestionKZ: "Сізде әлі де бөліскіңіз келетін ақпарат бар ма?",
          QuestionEN:
            "Do you have any other information that you would like to share?",
          Answer: "",
        },
        {
          ID: 17,
          QuestionRU:
            "Укажите, пожалуйста, откуда Вам стало известно о горячей линии Компании.",
          QuestionKZ:
            "Сіз компаниясының жедел байланыс желі туралы қалай білгеніңізді біле аламын ба?",
          QuestionEN: "May I ask you how you learned about the Hotline?",
          Answer: "",
        },
      ],
    },
  },
  setPersonalData: (payload) => {
    set((state) => ({ data: { ...state.data, userModel: { ...payload } } }));
  },
  setAnswer: (id, answer) => {
    const state = get();
    set(() => ({
      ...state,
      questionare: {
        ...state.data.questionare,
        questionares: state.data.questionare.questionares.map((q) =>
          q.ID === id ? { ...q, Answer: answer } : q,
        ),
      },
    }));
  },
  setOptions: (options: Options) => {
    set((state) => ({
      data: {
        ...state.data,
        DeclarerTypeID: options.DeclarerTypeID,
        DeclarerRoleID: options.DeclarerRoleID,
        organizationModel: { BranchID: options.organizationModel.BranchID },
      },
    }));
  },
  setAnswers: (answers) => {
    set((state) => ({
      data: {
        ...state.data,
        questionare: {
          ...state.data.questionare,
          questionares: state.data.questionare.questionares.map((q) => {
            const answer = answers.find((a) => a.id === q.ID - 1);
            return answer ? { ...q, Answer: answer.value } : q;
          }),
        },
      },
    }));
  },
}));

export default useDataStore;
