import React, { ComponentType, FC, SVGProps } from "react";
import MailIcon from "../../../assets/svg/MailIcon";
import PointerIcon from "../../../assets/svg/PointerIcon";
import WhatsAppSvg from "../../../assets/svg/WhatsAppSvg";
import Box from "../Box";

type IconComponent = ComponentType<SVGProps<SVGSVGElement>>;

type LinkType = "email" | "phone" | "whatsapp";

interface ContactItemProps {
  icon: IconComponent;
  title: string;
  description: string;
  link: string;
  linkType: LinkType;
}

const ContactItem: FC<ContactItemProps> = ({
  icon: Icon,
  title,
  description,
  link,
  linkType,
}) => (
  <Box className="flex flex-col items-center text-center">
    <Icon className="w-12 h-12 md:w-16 md:h-16" />
    <a
      href={link}
      className={`mt-4 md:mt-[26px] cursor-pointer text-white font-bold text-xl md:text-[24px] leading-tight md:leading-[34px] tracking-[-0.5px] ${
        linkType === "email" ? "relative border-b-2 pb-[1.5px]" : ""
      }`}
      {...(linkType === "email"
        ? { target: "_blank", rel: "noopener noreferrer" }
        : {})}
    >
      {title}
      {linkType === "email" && (
        <span className="absolute left-1/2 z-50 top-7">
          <PointerIcon className="w-4 h-4 md:w-5 md:h-5" />
        </span>
      )}
    </a>
    <p className="text-sm md:text-[17px] leading-snug md:leading-[29px] tracking-[-0.2px] text-white mt-3 md:mt-[16px] font-thin">
      {description}
    </p>
  </Box>
);

const ThirdSection: FC = () => {
  return (
    <section className="mt-8 md:mt-[70px] px-4 md:px-0">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-x-[34px] w-full max-w-[1200px] mx-auto">
        <ContactItem
          icon={WhatsAppSvg}
          title="8 800 080 4747"
          description="Звонки принимаются в будни с 9:00 до 18:30. В остальное время (включая праздники и выходные) обращения c возможностью записи поступают на автоответчик, их обработка осуществляется в первый рабочий день."
          link="tel:88000804747"
          linkType="phone"
        />
        <ContactItem
          icon={MailIcon}
          title="mail@sk-hotline.kz"
          description="Вы можете написать сообщение по электронной почте"
          link="mailto:mail@sk-hotline.kz"
          linkType="email"
        />
        <ContactItem
          icon={WhatsAppSvg}
          title="+7 771 191 8816"
          description="Вы можете связаться с оператором посредством мессенджера WhatsApp"
          link="https://wa.me/77711918816"
          linkType="whatsapp"
        />
      </div>
    </section>
  );
};

export default ThirdSection;
