import Input from "../Input";
import DropdownSelect from "../DropdownSelect";
import Button from "../home/Button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useDataStore from "../../store/store";

const messageOrganizations = [
  {
    value: 9,
    label: 'АО "ФНБ "Самрук-Казына"',
  },
  {
    value: 10,
    label: 'АО НК "КазМунайГаз"',
  },
  {
    value: 11,
    label: 'АО "Samruk-Kazyna Construction"',
  },
  {
    value: 8,
    label: 'ЧФ "Центр Научно-Технологических Инициатив "Самгау"',
  },
  {
    value: 12,
    label: 'АО НК "QazaqGaz"',
  },
  {
    value: 13,
    label: 'АО  "Эйр Астана"',
  },
  {
    value: 235,
    label: 'АО "НАК "Казатомпром"',
  },
  {
    value: 329,
    label: 'ТОО "Самрук - Қазына Инвест"',
  },
  {
    value: 340,
    label: 'ТОО "Самрук-Казына Бизнес Сервис"',
  },
  {
    value: 345,
    label:
      'АО "Казахстанская компания по управлению электрическими сетями" (Kazakhstan Electricity Grid Operating Company) "KEGOC"',
  },
  {
    value: 359,
    label: 'АО "Казахтелеком"',
  },
  {
    value: 388,
    label: 'АО "Казпочта"',
  },
  {
    value: 414,
    label: "АО «QAZAQ AIR»",
  },
  {
    value: 416,
    label: "АО «НГК «Тау-Кен Самрук»",
  },
  {
    value: 446,
    label: 'АО "НК "ҚТЖ"',
  },
  {
    value: 599,
    label: 'АО "Самрук-Энерго"',
  },
  {
    value: 633,
    label: "Samruk Business Academy",
  },
  {
    value: 2,
    label: 'ТОО "SK Ondeu"',
  },
  {
    value: 632,
    label: "КФ «Samruk-Kazyna Trust»",
  },
  {
    value: 328,
    label: 'ТОО "Самрук-Казына Контракт"',
  },
];

const PersonalDataForm = ({ onClickNext }: { onClickNext: () => void }) => {
  const { t } = useTranslation();
  const { setPersonalData, setOptions } = useDataStore((state) => state);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [options, setFormOptions] = useState({
    BranchID: undefined,
    DeclarerRoleID: undefined,
    DeclarerTypeID: undefined,
  });

  const handleForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSelectOption =
    (name: keyof typeof options) => (value: number) => {
      setFormOptions((prev) => ({
        ...prev,
        [name]: value,
      }));
    };

  const onSubmit = () => {
    setPersonalData({
      ID: 0,
      Name: form.name,
      Email: form.email,
      Phone: form.phone,
    });
    setOptions({
      organizationModel: {
        BranchID: options.BranchID,
      },
      DeclarerRoleID: options.DeclarerRoleID,
      DeclarerTypeID: options.DeclarerTypeID,
    });
    onClickNext();
  };

  const messageCategoryOptions = [
    {
      value: 1,
      label: t("messageCatStaff"),
    },
    {
      value: 2,
      label: t("messageCatCounterparty"),
    },
    {
      value: 3,
      label: t("messageCatAnonymous"),
    },
    {
      value: 4,
      label: t("messageCatOther"),
    },
  ];
  const messageTypeOptions = [
    {
      value: 1,
      label: t("messageTypeIndividual"),
    },
    {
      value: 2,
      label: t("messageTypeCollective"),
    },
    {
      value: 3,
      label: t("messageTypeAnonymous"),
    },
  ];
  return (
    <>
      <p className="text-[#161C2D] font-bold text-2xl md:text-[30px] leading-tight md:leading-[40px] mb-4 md:mb-[30px]">
        Обращение
      </p>
      <p className="text-[#161C2D] text-xl md:text-[24px] font-bold leading-tight md:leading-[32px] tracking-[-0.5px]">
        Укажите Ваши контактные данные
      </p>
      <p className="mt-2 md:mt-[8px] text-sm md:text-[16px] leading-snug md:leading-[24px] tracking-[-0.5px] mb-4 md:mb-[30px]">
        Контакты нужны для более быстрой и эффективной обработки обращения.
        <br />
        Оставляя контакты для связи Ваша анонимность при необходимости будет
        сохранена.
      </p>
      <div className="flex flex-col gap-y-4 md:gap-y-[24px]">
        <Input
          label="Имя"
          inputProps={{ name: "name", onChange: handleForm }}
        />
        <Input
          label="Email"
          inputProps={{ name: "email", onChange: handleForm }}
        />
        <Input
          label="Телефон"
          inputProps={{ name: "phone", onChange: handleForm }}
        />
      </div>
      <div className="flex flex-col mt-8 md:mt-[60px] mb-6 md:mb-[40px] gap-y-4 md:gap-y-[24px]">
        <DropdownSelect
          onSelect={handleSelectOption("DeclarerTypeID")}
          label="Тип обращения"
          options={messageCategoryOptions}
        />
        <DropdownSelect
          onSelect={handleSelectOption("DeclarerRoleID")}
          label="Ваша роль"
          options={messageTypeOptions}
        />
        <DropdownSelect
          onSelect={handleSelectOption("BranchID")}
          label="Организация, в отношении которой Вы хотите оставить обращение"
          options={messageOrganizations}
        />
      </div>
      <Button
        onClick={onSubmit}
        className="bg-[#A88C6D] text-white w-full md:w-auto"
      >
        Продолжить
      </Button>
    </>
  );
};

export default PersonalDataForm;
