import React, { useEffect, useState } from "react";
import Button from "./home/Button";
import ProgressBar from "./ProgressBar";
import PickFileIcon from "../assets/svg/PickFileIcon";
import CloseIcon from "../assets/svg/CloseIcon";
import SecondForm from "./messageForms/SecondForm";
import ThirdForm from "./messageForms/ThirdForm";
import FourthForm from "./messageForms/FourthForm";
import FifthForm from "./messageForms/FifthForm";
import SixthForm from "./messageForms/SixthForm";
import SeventhForm from "./messageForms/SeventhForm";
import EighthForm from "./messageForms/EighthForm";
import NinthForm from "./messageForms/NinthForm";
import TenthForm from "./messageForms/TenthForm";
import EleventhForm from "./messageForms/EleventhForm";
import TwelfthForm from "./messageForms/TwelfthForm";
import ThirteenthForm from "./messageForms/ThirteenthForm";
import FourteenthForm from "./messageForms/FourteenthForm";
import FirstForm from "./messageForms/FirstForm";

type Props = {
  step: number;
  handleNext: () => void;
  handlePrevious: () => void;
  selectedFiles: File[];
  handleFileRemove: (index: number) => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type FormComponent = React.ComponentType;

const forms: FormComponent[] = [
  FirstForm,
  SecondForm,
  ThirdForm,
  FourthForm,
  FifthForm,
  SixthForm,
  SeventhForm,
  EighthForm,
  NinthForm,
  TenthForm,
  EleventhForm,
  TwelfthForm,
  ThirteenthForm,
  FourteenthForm,
];

const FormContainer: React.FC<Props> = ({
  step,
  handlePrevious,
  handleNext,
  selectedFiles,
  handleFileRemove,
  handleFileChange,
}) => {
  const CurrentForm = forms[step - 1];

  return (
    <>
      <p className="text-[#161C2D] font-bold text-2xl md:text-[30px] leading-tight md:leading-[40px] mb-4 md:mb-[30px]">
        Обращение
      </p>
      <p className="text-[#161C2D] text-xl md:text-[24px] font-bold leading-tight md:leading-[32px] tracking-[-0.5px]">
        Ответьте на данные вопросы
      </p>
      <p className="mt-2 md:mt-[8px] text-sm md:text-[16px] leading-snug md:leading-[24px] tracking-[-0.5px] mb-8 md:mb-[60px]">
        Если Вы не можете ответить или не желаете отвечать на вопрос, оставьте
        поле пустым.
      </p>
      <ProgressBar step={step} totalSteps={15} />
      {CurrentForm && <CurrentForm />}
      <p className="mb-4 md:mb-[16px] text-black font-normal text-base md:text-[18px] mt-8 md:mt-[60px] leading-snug md:leading-[26px] tracking-[-0.1px]">
        Прикрепите фото или документы, которые могут помочь или выступить
        доказательством
      </p>
      <div className="flex flex-col mb-8 md:mb-[60px] mt-4 md:mt-[16px]">
        <label className="flex items-center cursor-pointer mb-4">
          <input
            type="file"
            onChange={handleFileChange}
            className="hidden"
            accept="image/*,.pdf,.doc,.docx"
            multiple
          />
          <PickFileIcon className="w-6 h-6 md:w-auto md:h-auto" />
          <p className="text-[#473BF0] ml-2 md:ml-[8px] font-normal text-sm md:text-[18px] leading-snug md:leading-[26px] tracking-[-0.1px]">
            Прикрепить файл (макс. 50 МБ)
          </p>
        </label>
        {selectedFiles.map((file, index) => (
          <div key={index} className="flex items-center mb-2">
            <p className="text-sm md:text-[16px] mr-2">{file.name}</p>
            <button
              onClick={() => handleFileRemove(index)}
              className="p-1 hover:bg-gray-100 rounded-full"
              aria-label="Удалить файл"
            >
              <CloseIcon className="w-4 h-4 md:w-auto md:h-auto" />
            </button>
          </div>
        ))}
      </div>
      <div className="flex flex-col md:flex-row gap-4 md:gap-x-5">
        <Button
          onClick={handlePrevious}
          className="border-2 border-[#A88C6D] text-[#A88C6D] w-full md:w-auto"
        >
          Назад
        </Button>
        <Button
          onClick={handleNext}
          className="bg-[#A88C6D] text-white w-full md:w-auto"
        >
          Далее
        </Button>
      </div>
    </>
  );
};

export default FormContainer;
