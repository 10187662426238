import React, { useState } from "react";
import AccordionItem from "../../AccordionItem";

export type FAQItem = {
  question: string;
  answer: string;
};

const faqData: FAQItem[] = [
  {
    question: "Что такое Горячая линия и для чего она нужна?",
    answer:
      "Горячая линия АО «Фонд национального благосостояния «Самрук-Казына» (далее – Компания) – это независимый, конфиденциальный и анонимный канал связи, обслуживаемый независимым Оператором, компанией KPMG, и предназначенный для обращений о фактических или потенциальных случаях нарушений законодательства и/или Кодекса поведения, в том числе случаев мошенничества, коррупции, дискриминации, неэтичного поведения и иных нарушений, относящихся к деятельности Компании.",
  },
  {
    question: "Кто может воспользоваться Горячей линией?",
    answer: "Ответ на вопрос о том, кто может воспользоваться Горячей линией.",
  },
  {
    question: "О каких видах нарушений я могу сообщить?",
    answer: "Информация о видах нарушений, о которых можно сообщить.",
  },
  {
    question: "Что такое Горячая линия и для чего она нужна?",
    answer:
      "Горячая линия АО «Фонд национального благосостояния «Самрук-Казына» (далее – Компания) – это независимый, конфиденциальный и анонимный канал связи, обслуживаемый независимым Оператором, компанией KPMG, и предназначенный для обращений о фактических или потенциальных случаях нарушений законодательства и/или Кодекса поведения, в том числе случаев мошенничества, коррупции, дискриминации, неэтичного поведения и иных нарушений, относящихся к деятельности Компании.",
  },
  {
    question: "Кто может воспользоваться Горячей линией?",
    answer: "Ответ на вопрос о том, кто может воспользоваться Горячей линией.",
  },
  {
    question: "О каких видах нарушений я могу сообщить?",
    answer: "Информация о видах нарушений, о которых можно сообщить.",
  },
  // Добавьте еще вопросы, если они есть
];

const QuestionsSection: React.FC = () => {
  const [openIndices, setOpenIndices] = useState<number[]>([]);
  const [visibleQuestions, setVisibleQuestions] = useState(3);

  const toggleAccordion = (index: number) => {
    setOpenIndices((prevIndices) =>
      prevIndices.includes(index)
        ? prevIndices.filter((i) => i !== index)
        : [...prevIndices, index],
    );
  };

  const showMoreQuestions = () => {
    setVisibleQuestions((prev) => Math.min(prev + 3, faqData.length));
  };

  return (
    <section className="px-4 md:pl-[17%] mt-12 md:mt-[90px]">
      <h1 className="text-[#17335D] font-bold text-2xl md:text-5xl mb-6 md:mb-12 leading-tight">
        Часто задаваемые вопросы
      </h1>
      <div
        className={`w-full md:max-w-[880px] ${
          visibleQuestions < faqData.length ? "" : "mb-6 md:mb-[40px]"
        } space-y-2.5`}
      >
        {faqData.slice(0, visibleQuestions).map((item, index) => (
          <AccordionItem
            key={index}
            item={item}
            isOpen={openIndices.includes(index)}
            toggleAccordion={() => toggleAccordion(index)}
          />
        ))}
      </div>
      {visibleQuestions < faqData.length && (
        <p
          onClick={showMoreQuestions}
          className="text-[#473BF0] cursor-pointer mb-6 md:mb-[40px] text-lg md:text-[24px] leading-tight md:leading-[34px] tracking-[-0.5px] mt-4 md:mt-[26px] underline"
        >
          Показать еще
        </p>
      )}
    </section>
  );
};

export default QuestionsSection;
