import React, { useEffect, useState } from "react";

interface ProgressBarProps {
  step: number;
  totalSteps: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ step, totalSteps }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const progress = (step / totalSteps) * 100;
    setWidth(progress);
  }, [step, totalSteps]);

  return (
    <div className="w-full md:w-[670px] mb-4 md:mb-[30px] h-1 bg-[rgba(23,51,93,0.3)] rounded-[2px] overflow-hidden">
      <div
        className="h-full bg-[#17335D] transition-all duration-500 ease-out"
        style={{ width: `${width}%` }}
      />
    </div>
  );
};

export default ProgressBar;
