import React from "react";
import Block from "../Block";

const SecondSection = () => {
  return (
    <section className="mt-8 md:mt-[70px] w-full items-center flex flex-col px-4 md:px-0">
      <h2 className="tracking-normal md:tracking-[-1.8px] mb-8 md:mb-[70px] text-center leading-tight md:leading-[58px] text-[#17335D] max-w-[950px] font-bold text-2xl md:text-[48px]">
        Основные сведения о работе Горячей линии АО «Самрук-Казына»
      </h2>
      <div className="flex flex-col md:flex-row justify-center gap-6 md:gap-x-[35px] w-full max-w-[1200px]">
        <Block>Неотъемлемая часть культуры открытого общения</Block>
        <Block>Обслуживается независимым оператором</Block>
        <Block>
          Можно обратиться анонимно, вся информация строго конфиденциальна
        </Block>
      </div>
      <div className="flex flex-col md:flex-row mt-6 md:mt-[60px] justify-center gap-6 md:gap-x-[35px] w-full max-w-[1200px]">
        <Block>
          Рассматривается информирование о любых нарушениях законодательства или
          кодекса поведения, в том числе и о фактах или предполагаемых случаях
          мошенничества, коррупции, дискриминации, неэтичного поведения, и иных
          нарушениях коррупционного характера
        </Block>
        <Block>
          Доступна для всех партнеров и сотрудников АО «Самрук-Казына» (кроме
          телефонной связи звонки принимаются в будни, с 09:00 до 18:30)
        </Block>
        <Block>
          Политика информирования АО «Самрук-Казына» не допускает преследования
          лиц, добросовестно сообщивших о нарушениях
        </Block>
      </div>
    </section>
  );
};

export default SecondSection;
