import axios from "axios";

const apiInstance = axios.create({
    baseURL: "http://195.93.152.76",
});

const postMessage = (
  data: any,
  fileBase64Data: { [key: string]: string },
  navigate: (code: string) => void,
) => {
  const formData = new FormData();

  // Combine the form data and file data
  const combinedData = {
    ...data,
    files: fileBase64Data,
  };

  formData.append("data", JSON.stringify(combinedData));

  apiInstance
      .post("/api/ExtDictionary/PostQuestionnaireData", formData, {})
    .then((response) => {
      console.log("Response:", response.data);
      navigate(response.data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

type GetStatus = (data: string, callback: (status: unknown) => void) => void;

const getStatus: GetStatus = (data, callback) => {
  apiInstance
    .get(`/api/ExtDictionary/GetStatus/status?code=${data}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      callback(response.data.data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export { postMessage, getStatus };
