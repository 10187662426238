import React from "react";

const PickFileIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 8.95313L9.43557 16.5176C8.50887 17.4443 7.25199 17.9649 5.94144 17.9649C4.63089 17.9649 3.37401 17.4443 2.44731 16.5176C1.52061 15.5909 1 14.334 1 13.0234C1 11.7129 1.52061 10.456 2.44731 9.52931L10.0117 1.96488C10.6295 1.34708 11.4675 1 12.3412 1C13.2149 1 14.0528 1.34708 14.6706 1.96488C15.2884 2.58268 15.6355 3.42059 15.6355 4.29429C15.6355 5.16799 15.2884 6.00591 14.6706 6.62371L7.09792 14.1881C6.78902 14.497 6.37006 14.6706 5.93321 14.6706C5.49636 14.6706 5.0774 14.497 4.7685 14.1881C4.4596 13.8792 4.28606 13.4603 4.28606 13.0234C4.28606 12.5866 4.4596 12.1676 4.7685 11.8587L11.7568 4.87871"
      stroke="#473BF0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PickFileIcon;
