import React from "react";
import { Link } from "react-router-dom";

type Props = {
  currentPage?: string;
};

const Chevron = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-shrink-0"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.52864 3.52864C5.78899 3.26829 6.2111 3.26829 6.47145 3.52864L10.4714 7.52864C10.7318 7.78899 10.7318 8.2111 10.4714 8.47145L6.47145 12.4714C6.2111 12.7318 5.78899 12.7318 5.52864 12.4714C5.26829 12.2111 5.26829 11.789 5.52864 11.5286L9.05723 8.00004L5.52864 4.47145C5.26829 4.2111 5.26829 3.78899 5.52864 3.52864Z"
      fill="#C8C8C8"
    />
  </svg>
);

const Breadcrumbs = ({ currentPage }: Props) => {
  return (
    <div className="flex items-center flex-wrap mt-4 md:mt-[20px] mb-4 md:mb-[30px]">
      <Link to="/" className="flex items-center">
        <p className="text-[#473BF0] cursor-pointer font-normal mr-1 md:mr-[4px] text-xs md:text-[14px] leading-5 md:leading-[20px]">
          Главная
        </p>
      </Link>

      <Chevron />
      <p className="ml-1 md:ml-[8px] text-xs md:text-[14px] leading-5 md:leading-[24px] tracking-[-0.5px] text-[#92939B] break-all">
        {currentPage}
      </p>
    </div>
  );
};

export default Breadcrumbs;
