import { FC } from "react";
import FormInput from "../FormInput";
import useFormsStore from "../../store/formValuesStore";
import { useTranslation } from "react-i18next";

const TenthForm: FC = () => {
  const { values, setValue } = useFormsStore();
  const handleChange = (id: number) => (value: string) => {
    setValue(id, value);
  };
  const { t } = useTranslation();

  return (
    <div className="flex gap-y-[30px] flex-col">
      <FormInput
        onChangeText={handleChange(10)}
        value={values[10].value}
        label={t("questions.11")}
      />
      <FormInput
        onChangeText={handleChange(11)}
        value={values[11].value}
        label={t("questions.12")}
      />
    </div>
  );
};

export default TenthForm;
