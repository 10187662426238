import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../Button";

const operatorImage = () => import("../../../assets/images/operator.png");
const operatorWebp = () => import("../../../assets/images/operator.webp");

const FirstSection: React.FC = () => {
  const { t } = useTranslation();
  const [imageSrc, setImageSrc] = useState<string>("");
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  useEffect(() => {
    const loadImage = async () => {
      try {
        // Пробуем загрузить WebP версию
        const webp = await operatorWebp();
        setImageSrc(webp.default);
      } catch {
        // Если WebP не поддерживается, загружаем PNG
        const png = await operatorImage();
        setImageSrc(png.default);
      }
    };

    loadImage();
  }, []);

  return (
    <section className="flex flex-col md:flex-row w-full">
      <div className="w-full md:w-[476px] h-[300px] md:h-auto relative bg-gray-200">
        {!imageLoaded && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        )}
        {imageSrc && (
          <img
            src={imageSrc}
            alt="home_image"
            className={`w-full h-full object-cover transition-opacity duration-300 ${
              imageLoaded ? "opacity-100" : "opacity-0"
            }`}
            onLoad={() => setImageLoaded(true)}
          />
        )}
      </div>
      <div className="bg-gradient-to-tr from-[#313C59] to-[#161C2D] p-6 md:pt-10 md:pl-[70px] w-full">
        <h1 className="font-bold tracking-[-1px] md:tracking-[-2px] text-white text-3xl md:text-[54px] leading-tight md:leading-[65px] mb-4 md:mb-[30px] max-w-[590px]">
          {t("hotlineTitle")}
        </h1>
        <p className="font-normal text-white text-base md:text-[18px] leading-relaxed md:leading-[32px] tracking-[-0.2px] max-w-[660px] mb-6 md:mb-[30px]">
          {t("mainTitle")}
        </p>
        <div className="flex flex-col md:flex-row gap-4 md:gap-x-10">
          <Link to="/message" className="w-full md:w-auto">
            <Button className="bg-[#A88C6D] text-white w-full md:w-auto">
              {t("leaveMessageTitle")}
            </Button>
          </Link>
          <Link to="/status" className="w-full md:w-auto">
            <Button className="bg-[#FDFDFF] text-[#A88C6D] w-full md:w-auto">
              {t("checkMessageStatusTitle")}
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default FirstSection;
