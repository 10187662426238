import React, { useState } from "react";
import Container from "../components/Container";
import Header from "../components/Header";
import Breadcrumbs from "../components/Breadcrumbs";
import Input from "../components/Input";
import Button from "../components/home/Button";
import { Link } from "react-router-dom";
import { getStatus } from "../api";

const CheckStatus = ({
  onClickCheck,
}: {
  onClickCheck: (code: string) => void;
}) => {
  const [inputValue, setInputValue] = useState("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <>
      <p className="mt-4 md:mt-[30px] text-base md:text-[18px] leading-6 md:leading-[32px] tracking-[-0.5px] mb-3 md:mb-[16px]">
        Введите код Вашего обращения
      </p>
      <Input
        inputProps={{ value: inputValue, onChange: handleChange }}
        className="w-28 md:w-[103px] uppercase"
        label="Код обращения"
      />
      <Button
        onClick={() => onClickCheck(inputValue)}
        className="bg-[#A88C6D] text-white mt-6 md:mt-[30px] w-full md:w-auto"
      >
        Проверить статус обращения
      </Button>
    </>
  );
};

const Status = () => {
  return (
    <>
      <p className="text-[#161C2D] font-bold text-xl md:text-[24px] leading-tight md:leading-[32px] tracking-[-0.5px] mb-4 md:mb-[20px]">
        Обращение № FF5240
      </p>
      <p className="w-full md:w-[715px] text-base md:text-[18px] leading-6 md:leading-[32px] tracking-[-0.5px]">
        В ответ на анонимное обращение за №FF5240 от 22.05.2023 года (далее –
        Обращение) сообщаем следующее: <br /> <br />
        Согласно требованиям, предъявляемым к содержанию обращений,
        предусмотренные ст.63 Административного процедурно-процессуального
        кодекса Республики Казахстан (далее- АППК) следует, что любое обращение
        должно иметь суть обращения. <br /> <br /> В ходе изучения обращения
        установлено, что в нем не приведены конкретные и предметные нарушения со
        стороны должностных лиц ТОО «Богатырь Комир», необходимые для начала
        проведения служебной проверки, т.е. не отражена суть обращения. <br />{" "}
        <br />
        На основании вышеизложенного, данное обращение будет рассмотрено после
        предоставления заявителем дополнительной и исчерпывающей информацией с
        приведением более детальных фактов указывающих на нарушения.
      </p>
      <Link to="/">
        <Button className="bg-[#A88C6D] text-white mt-6 md:mt-[30px] w-full md:w-auto">
          На Главную
        </Button>
      </Link>
    </>
  );
};

const WaitingStatus = () => (
  <>
    <p className="text-[#161C2D] font-bold text-xl md:text-[24px] leading-tight md:leading-[32px] tracking-[-0.5px] mb-4 md:mb-[20px]">
      Обращение № FF5240
    </p>
    <p className="w-full md:w-[715px] text-lg md:text-[24px] leading-tight md:leading-[32px] tracking-[-0.5px]">
      Обращение находится на рассмотрении. Ответ готовится в течение 15 рабочих
      дней.
    </p>
    <Link to="/">
      <Button className="bg-[#A88C6D] text-white mt-6 md:mt-[30px] w-full md:w-auto">
        На Главную
      </Button>
    </Link>
  </>
);

const CheckStatusPage = () => {
  const [currentTab, setCurrentTab] = useState<
    "WaitingStatus" | "Status" | "CheckStatus"
  >("CheckStatus");

  const callback = () => {
    setCurrentTab("Status");
  };

  const handleClick = (code: string) => {
    setCurrentTab("Status");
    getStatus(code, callback);
  };

  return (
    <main className="bg-[#F5F5F5] min-h-screen">
      <Header className="bg-[#F5F5F5]" />
      <div className="bg-[#F5F5F5] px-4 md:px-[163px] pb-8 md:pb-[60px]">
        <Breadcrumbs currentPage="Проверить статус обращения" />
        <Container>
          <p className="text-[#161C2D] font-bold text-2xl md:text-[30px] leading-tight md:leading-[40px] mb-6 md:mb-[30px]">
            Статус обращения
          </p>
          {currentTab === "CheckStatus" && (
            <CheckStatus onClickCheck={handleClick} />
          )}
          {currentTab === "Status" && <Status />}
          {currentTab === "WaitingStatus" && <WaitingStatus />}
        </Container>
      </div>
    </main>
  );
};

export default CheckStatusPage;
