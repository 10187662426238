import React, { PropsWithChildren } from "react";

type Props = { className?: string } & PropsWithChildren;

const Container = ({ children, className = "min-h-[75vh]" }: Props) => {
  return (
    <div className={`${className} bg-white p-4 md:p-[30px] rounded-[10px]`}>
      {children}
    </div>
  );
};

export default Container;
