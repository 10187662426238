import React, { useState, useId } from "react";

type Option = {
  value: number;
  label: string;
};

type Props = {
  label?: string;
  options: Option[];
  onSelect: (value: number) => void;
};

const DropdownSelect = ({ label, options, onSelect }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const htmlFor = useId();

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    onSelect(option.value);
    setIsOpen(false);
  };

  return (
    <div className="flex flex-col relative w-full">
      <label
        className="mb-1 md:mb-[6px] text-black text-xs md:text-[14px] leading-5 md:leading-[26px] tracking-[-0.1px]"
        htmlFor={htmlFor}
      >
        {label}
      </label>
      <div
        className="h-10 md:h-[50px] rounded-[8px] border border-[#E7E9ED] w-full md:w-[480px] px-3 md:px-[16px] text-black text-sm md:text-[14px] leading-5 md:leading-[26px] tracking-[-0.1px] flex items-center justify-between cursor-pointer"
        onClick={toggleDropdown}
      >
        <span>{selectedOption ? selectedOption.label : ""}</span>
        <svg
          width="10"
          height="5"
          viewBox="0 0 10 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`transform transition-transform duration-300 ${isOpen ? "rotate-180" : ""}`}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0L5 5L10 0H0Z"
            fill="#161C2D"
          />
        </svg>
      </div>
      <div
        className={`absolute top-full left-0 w-full md:w-[480px] mt-1 bg-white border border-[#E7E9ED] rounded-[8px] shadow-lg z-10 transition-all duration-300 ease-in-out ${
          isOpen
            ? "opacity-100 max-h-[300px] overflow-y-auto"
            : "opacity-0 max-h-0 overflow-hidden"
        }`}
      >
        <ul>
          {options.map((option) => (
            <li
              key={option.value}
              className="px-3 md:px-[16px] py-2 md:py-[8px] hover:bg-gray-100 cursor-pointer transition-colors duration-100 text-sm md:text-[14px]"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropdownSelect;
